.App {
	width: 100%;
	overflow: auto;
	background-color: #dadad9;
}

.App-container {
	width: 67.5%;
	max-width: 1296px;
	box-shadow: 0px 0px 0px 30px;
	margin: 0 auto;
	height: 100%;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3) !important;
}

.App-header {
	background-color: #ffffff !important;
	min-height: 50px;
	box-shadow: none !important;
	position: unset !important;
	padding: 0px !important;
}

.App-header--toolbar {
	color: #ffffff;
	background-color: #ffffff;
	width: 100% !important;
	padding: 0px !important;
}

.App-header--logo {
	height: 100px;
	width: 300px;
	padding: 9px 32px;
}

.MuiLink-underlineHover:hover {
	text-decoration: none !important;
	border-bottom: 2px solid #d65200;
}

.my-dashboard {
	font-size: 18px;
	color: #000352 !important;
	font-weight: 700;
	cursor: pointer;
}

.support {
	font-size: 18px;
	color: #000352 !important;
	font-weight: 700;
	margin-left: 33px !important;
	cursor: pointer;
}

.App-header--links {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	margin-right: 32px;
	margin-top: 10px;
}

_:-ms-fullscreen,
:root .MuiAppBar-positionFixed {
	position: relative;
}

.Hero-container {
	display: flex;
	justify-content: center;
}

.Hero-opacity {
	width: 100%;
	height: 190px;
	position: relative;
}

.Hero-text {
	font-family: 'League Spartan', sans-serif !important;
	color: #ffffff;
	font-weight: 700;
	position: relative;
	bottom: 65%;
	font-size: 42px;
	text-transform: uppercase;
	text-shadow: 3px 1px 10px #000000;
	display: flex;
	justify-content: center;
}

.Hero-image {
	width: 100%;
	height: 190px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
		url(./Images/Dash\ Hero.jpg);
}

.Download-container {
	display: flex;
	flex-direction: column;
	margin: 18px auto;
}

.Download-container--thanks {
	font-family: 'League Spartan', sans-serif !important;
	display: flex;
	justify-content: center;
	font-size: 28px;
	color: #000352;
	text-transform: uppercase;
	font-weight: bold;
}

.Download-container--thanks > span {
	border-bottom: 2px solid #d65200;
	font-size: 31px;
}

.Download-container--msg {
	display: flex;
	justify-content: center;
	text-align: center;
	font-family: 'Open Sans', sans-serif !important;
}

.Download-container--msg > span {
	width: 675px;
	margin-top: 30px;
	font-size: 22px;
}

.Download-container--btn {
	background-color: #d65200 !important;
	color: #ffffff !important;
	width: 272px;
	margin: 20px auto 0px auto !important;
	border-radius: 0px !important;
	font-family: 'Open Sans', sans-serif !important;
	font-weight: bold !important;
	text-transform: none !important;
	font-size: 14px !important;
}

.Download-container--btn-container {
	display: flex;
	margin-top: 17px;
	margin-bottom: 26px;
}

.Video-container {
	background-color: #000352;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 20px;
}

.Video-container--videos {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 45px;
}

.Video-container--msg {
	display: flex;
	justify-content: center;
	padding: 10px;
	margin: 30px 0px;
	font-size: 17px;
	font-weight: bold;
	font-family: 'Open Sans', sans-serif !important;
	text-transform: uppercase;
}

.Video-left {
	margin-right: 42px;
	border: 3px solid white !important;
}

.Video-right {
	border: 3px solid white !important;
}

.footer {
	display: flex;
	height: 50px;
	padding: 10px;
	margin: 0 auto;
	border-top: 1px solid #dadad9;
	width: 95%;
	align-items: center;
	justify-content: space-between;
}

.footer-right--support-link {
	margin-left: 10px;
}

.modal-title {
	padding: 16px 90px !important;
}

.modal-title > h2 > span {
	text-align: center;
	color: #000352;
	font-weight: bold;
	font-family: 'League Spartan', sans-serif !important;
	text-transform: uppercase;
	border-bottom: 2px solid #d65200;
}

.modal-title > h2 {
	display: flex;
	justify-content: center;
}

.modal {
	padding: 20px;
}

.modal > div.MuiDialog-container > div {
	bottom: 12%;
	height: 365px;
	width: 600px;
	border-radius: 0px;
}

.modal-subtitle {
	display: flex;
	justify-content: center;
	margin-bottom: 45px;
}

.modal-image-container {
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.modal-images {
	display: flex;
	justify-content: space-evenly;
}

.modal-close-btn {
	color: #ffffff !important;
	background-color: #d65200 !important;
	margin: 45px auto 0px auto !important;
	border-radius: 0px !important;
	font-family: 'Open Sans', sans-serif !important;
	font-weight: bold !important;
	font-size: 14px !important;
	display: flex;
	justify-content: center;
	width: 250px;
	text-transform: none !important;
}

.modal-images--blended {
	margin-left: 10px;
}

.modal-images--command {
	margin-right: 10px;
}

.modal--image {
	width: 150px;
}

.Video-carousel {
	display: none;
}

.mobile-menu-icon {
	display: none !important;
}

@media only screen and (max-width: 480px) {
	.App-header--logo {
		height: 66px;
		width: 200px;
		padding: 10px 30px 10px 10px;
	}

	.App-container {
		width: 100%;
	}

	.App-header--links {
		display: none;
	}

	.mobile-menu-icon {
		display: inline-flex !important;
		color: #000352 !important;
		left: 110px;
		top: 5px;
	}

	.Hero-opacity {
		height: 130px;
	}

	.Hero-image {
		height: 130px;
	}

	.Hero-text {
		font-size: 28px;
		bottom: 70%;
	}

	.Hero-container {
		margin-top: 0px;
	}

	.Download-container--thanks {
		margin-right: 0px;
	}

	.Download-container--thanks > span {
		font-size: 26px;
	}

	.Download-container--msg > span {
		margin-right: 0px;
		font-size: 14px;
		padding: 0px 15px;
	}

	.Download-container--btn-container {
		margin-right: 0px;
		margin-top: 0px;
	}

	.Download-container--btn {
		width: 275px;
	}

	.Video-container--videos {
		display: none;
	}

	.Video-carousel {
		display: unset;
		padding: 0px 9px;
	}

	.carousel .slide {
		background: unset !important;
	}

	.Video-container {
		padding-bottom: 0px;
		height: 300px;
	}

	.footer {
		flex-direction: column-reverse;
		border-top: none;
		margin: 0 auto;
		padding: 25px 10px 20px 10px;
	}

	.modal > div.MuiDialog-container > div {
		width: 375px;
		overflow-y: auto;
		height: 600px;
		margin: 0px !important;
		bottom: unset;
	}

	.modal-images--blended {
		margin-left: 0px;
	}

	.modal-images--command {
		margin-right: 0px;
	}

	.modal-images {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.modal-title {
		padding: 30px 10px 10px 10px !important;
	}

	.modal-title > h2 > span {
		font-size: 18px !important;
	}

	.thumbs-wrapper {
		margin: 0px !important;
	}

	.modal-subtitle {
		margin-bottom: 30px;
	}

	.modal--image {
		margin-bottom: 30px;
	}

	.modal-close-btn {
		margin: 0 auto !important;
		text-transform: none !important;
		width: 230px;
	}

	.modal-image-container {
		padding: 10px;
	}

	.carousel .slide iframe {
		margin: 20px 40px 60px 40px !important;
	}

	.Video-container--msg {
		margin: 70px 0px 0px 0px;
	}

	.carousel .control-dots {
		z-index: 2 !important;
		bottom: 60px !important;
	}

	.slide {
		height: 300px !important;
	}

	.carousel.carousel-slider .control-arrow {
		bottom: 100px !important;
	}

	.carousel .control-prev.control-arrow:before {
		border-right: 15px solid #ffffff !important;
	}

	.carousel .control-next.control-arrow:before {
		border-left: 15px solid #ffffff !important;
	}

	.carousel .control-arrow:before,
	.carousel.carousel-slider .control-arrow:before {
		border-top: 10px solid transparent !important;
		border-bottom: 10px solid transparent !important;
	}
}

@media only screen and (max-width: 380px) {
	.mobile-menu-icon {
		left: 75px;
	}

	.modal > div.MuiDialog-container > div {
		height: 650px;
	}
}
